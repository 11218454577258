$header-color: #fff;
$header-color-secondary: #bbb;
$header-color-hover: darken($header-color, 20%);

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
  background-color: rgba(170, 0, 0, 0.5);
  left: -3.5em;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 30px;
  white-space: nowrap;
  width: 15em;
  z-index: 99999;
  pointer-events: none;
  opacity: 0.75;
  a {
    color: #fff;
    display: block;
    font-weight: 400;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
  }
}

/* ==========================================================================
Navbar styles
========================================================================== */
.navbar-brand {
  overflow: hidden;
}

.jh-navbar .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-version {
  font-size: 10px;
  color: $header-color-secondary;
  padding: 0 0 0 10px;
}

.brand-logo {
  &:hover {
    text-decoration: none;
  }
  .brand-icon {
    height: 35px;
    width: auto;
    display: inline-block;
    img {
      height: 45px;
    }
  }
}

.brand-title {
  font-size: 24px;
  color: $header-color;
  &:hover {
    color: $header-color-hover;
    text-decoration: none;
  }
}

.loading-bar {
  height: 3px;
  background-color: #009cd8;
  position: absolute;
  top: 0px;
  z-index: 1031;
}
