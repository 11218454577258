
.bg-video {
  z-index: -10;
  opacity: 0.3;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.bg-still {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
