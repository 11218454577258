/* ==========================================================================
Main page styles
========================================================================== */
.logo {
  display: inline-block;
  width: 100%;
  height: 497px;
  background: url('../../../content/images/logo-open-heaven.png') no-repeat center top;
  background-size: contain;
}
.logo-bg {
  background: url('../../../content/images/banner-5.jpg') no-repeat center top;
  background-size: cover;
}

.faq > h2 {
  border-bottom: 6px solid var(--bs-primary);
  padding-bottom: 6px;
  text-align: center;
}

hr.solid {
  border-top: 2px solid #999;
}

.divider{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  hr {
    flex-grow: 1;
  }
}
